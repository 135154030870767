.search-results {
	// padding: 3rem 0;
	display: flex;
	flex-direction: column;
	background-color: inherit;
	// #f2f2f4
	// #fafafc
}

.results {
	list-style: none;
	margin-bottom: 2rem;
}

.pagination {
	margin-top: auto;
	padding: 0 3.5rem;

	&::after {
		content: "";
		display: table;
		clear: both;
	}

	&__btn {
		&--prev {
			float: left;
			border-radius: 0.8rem;
		}
		&--next {
			float: right;
			border-radius: 0.8rem;
		}
	}
}

.copyright {
	color: $color-grey-dark-2;
	font-size: 1.2rem;
	padding: 0 3.5rem;
	margin-top: 4rem;

	.twitter-link:link,
	.twitter-link:visited {
		color: $color-grey-dark-2;
	}
}
