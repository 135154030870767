* {
	margin: 0;
	padding: 0;
}

*,
*::before,
*::after {
	-webkit-box-sizing: inherit;
	box-sizing: inherit;
}

html {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 64%;
}
@media only screen and (max-width: 61.25em) {
	html {
		font-size: 50%;
	}
}

body {
	font-family: "Comic Neue", cursive;
	font-weight: 400;
	line-height: 1.6;
	color: #615551;
	background-color: #f9f5f3;
	background-size: cover;
	background-repeat: no-repeat;
	min-height: calc(100vh - 8vw);
	font-smooth: antialiased;
	-moz-osx-font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
}

.container {
	border: 0.1px solid rgba(0, 0, 0, 0.2);
	max-width: 125rem;
	min-height: 117rem;
	margin: 5rem auto;
	background-color: #fff;
	border-radius: 2px;
	overflow: hidden;
	-webkit-box-shadow: 10px 10px rgba(0, 0, 82, 0.1019607843);
	box-shadow: 10px 10px rgba(0, 0, 82, 0.1019607843);
	display: grid;
	grid-template-rows: 10rem minmax(100rem, auto);
	grid-template-columns: 1fr 3fr;
	grid-template-areas: "head head" "list recipe";
}
@media only screen and (max-width: 81.25em) {
	.container {
		max-width: 100%;
		margin: 0;
		border-radius: 0;
	}
}

.btn--small,
.btn--small:link,
.btn--small:visited,
.btn {
	background-color: #326273;
	border-radius: 0rem 1rem 1rem 0rem;
	border: none;
	text-transform: uppercase;
	color: #fff;
	cursor: pointer;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
}
.btn--small:hover,
.btn:hover {
	-webkit-transform: scale(1.05);
	transform: scale(1.05);
}
.btn--small:focus,
.btn:focus {
	outline: none;
}
.btn--small > *:first-child,
.btn > *:first-child {
	margin: 0.3rem;
}

.btn {
	padding: 1rem 2rem;
	font-size: 1.5rem;
	font-weight: 600;
}
.btn svg {
	height: 2.25rem;
	width: 2.25rem;
	fill: currentColor;
}

.btn--small,
.btn--small:link,
.btn--small:visited {
	font-size: 1.4rem;
	font-weight: 600;
	padding: 1.25rem 2.25rem;
	text-decoration: none;
}
.btn--small svg,
.btn--small:link svg,
.btn--small:visited svg {
	height: 1.75rem;
	width: 1.75rem;
	fill: currentColor;
}

.btn--inline {
	font-family: "Comic Neue", cursive;
	color: #326273;
	font-size: 1.3rem;
	font-weight: 600;
	border: none;
	background-color: #f9f5f3;
	padding: 0.8rem 1.2rem;
	border-radius: 10rem;
	cursor: pointer;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
}
.btn--inline svg {
	height: 1.6rem;
	width: 1.6rem;
	fill: currentColor;
	margin: 0 0.2rem;
}
.btn--inline span {
	margin: 0 0.4rem;
}
.btn--inline:hover {
	color: #6b5500;
	background-color: #f2efee;
}
.btn--inline:focus {
	outline: none;
}

.btn--round {
	background-color: #326273;
	border-radius: 50%;
	border: none;
	cursor: pointer;
	height: 4.5rem;
	width: 4.5rem;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.btn--round:hover {
	-webkit-transform: scale(1.07);
	transform: scale(1.07);
}
.btn--round:focus {
	outline: none;
}
.btn--round svg {
	height: 2.5rem;
	width: 2.5rem;
	fill: #fff;
}

.btn--tiny {
	height: 2rem;
	width: 2rem;
	border: none;
	background: none;
	cursor: pointer;
}
.btn--tiny svg {
	height: 100%;
	width: 100%;
	fill: #326273;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.btn--tiny:focus {
	outline: none;
}
.btn--tiny:hover svg {
	fill: #6b5500;
	-webkit-transform: translateY(-1px);
	transform: translateY(-1px);
}
.btn--tiny:active svg {
	fill: #6b5500;
	-webkit-transform: translateY(0);
	transform: translateY(0);
}
.btn--tiny:not(:last-child) {
	margin-right: 0.3rem;
}

.heading--2 {
	font-size: 2rem;
	font-weight: 700;
	color: #326273;
	text-transform: uppercase;
	margin-bottom: 2.5rem;
	text-align: center;
}

.link:link,
.link:visited {
	color: #918581;
}

@-webkit-keyframes spinner {
	0%,
	100% {
		-webkit-animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
		animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
	}
	0% {
		-webkit-transform: rotateY(0deg);
		transform: rotateY(0deg);
	}
	50% {
		-webkit-transform: rotateY(1800deg);
		transform: rotateY(1800deg);
		-webkit-animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
		animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
	}
	100% {
		-webkit-transform: rotateY(3600deg);
		transform: rotateY(3600deg);
	}
}

@keyframes spinner {
	0%,
	100% {
		-webkit-animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
		animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
	}
	0% {
		-webkit-transform: rotateY(0deg);
		transform: rotateY(0deg);
	}
	50% {
		-webkit-transform: rotateY(1800deg);
		transform: rotateY(1800deg);
		-webkit-animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
		animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
	}
	100% {
		-webkit-transform: rotateY(3600deg);
		transform: rotateY(3600deg);
	}
}
.spinner {
	display: block;
	-webkit-transform: translateZ(1px);
	transform: translateZ(1px);
}
.spinner > div {
	display: block;
	height: 5rem;
	width: 5rem;
	margin: 15rem auto;
	border-radius: 50%;
	background: rgba(50, 98, 115, 0.5);
	-webkit-animation: spinner 5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
	animation: spinner 5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@-webkit-keyframes rotate {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes rotate {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
.message,
.error {
	max-width: 40rem;
	margin: 0 auto;
	padding: 5rem 4rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: end;
}
.message svg,
.error svg {
	color: #326273;
	height: 4rem;
	width: 4rem;
	fill: #326273;
	-webkit-transform: translateY(-0.3rem);
	transform: translateY(-0.3rem);
}
.message p,
.error p {
	margin-left: 1.5rem;
	font-size: 1.8rem;
	line-height: 1.5;
	font-weight: 600;
}

.header {
	grid-area: head;
	background-color: inherit;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-bottom: solid rgba(0, 0, 0, 0.05);
	background-image: linear-gradient(132deg, rgba(24, 107, 121, 0.0705882353), transparent 120%);
}
.header__logo {
	height: 12rem;
	margin-left: 0;
	display: block;
}

.search {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 45%;
	flex: 0 0 45%;
	background-color: #fff;
	border-radius: 1rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding-left: 3rem;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	border: 0.1px solid rgba(0, 0, 0, 0.2784313725);
}
.search:focus-within {
	-webkit-transform: translateY(-2px);
	transform: translateY(-2px);
	-webkit-box-shadow: 6px 8px 0px rgba(0, 0, 82, 0.1019607843);
	box-shadow: 6px 8px 0px rgba(0, 0, 82, 0.1019607843);
}
.search__field {
	border: none;
	background: none;
	font-family: inherit;
	color: inherit;
	font-size: 1.5rem;
	width: 100%;
}
.search__field:focus {
	outline: none;
}
.search__field::-webkit-input-placeholder {
	color: #d3c7c3;
}
.search__field::-moz-placeholder {
	color: #d3c7c3;
}
.search__field:-ms-input-placeholder {
	color: #d3c7c3;
}
.search__field::-ms-input-placeholder {
	color: #d3c7c3;
}
.search__field::placeholder {
	color: #d3c7c3;
}
@media only screen and (max-width: 61.25em) {
	.search__field::-webkit-input-placeholder {
		color: white;
	}
	.search__field::-moz-placeholder {
		color: white;
	}
	.search__field:-ms-input-placeholder {
		color: white;
	}
	.search__field::-ms-input-placeholder {
		color: white;
	}
	.search__field::placeholder {
		color: white;
	}
}
.search__btn {
	font-weight: 300;
	font-family: inherit;
}

.nav {
	-ms-flex-item-align: stretch;
	align-self: stretch;
	margin-right: 2.5rem;
}
.nav__list {
	list-style: none;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
}
.nav__item {
	position: relative;
}
.nav__btn {
	height: 100%;
	font-family: inherit;
	color: inherit;
	font-size: 1.4rem;
	font-weight: 700;
	text-transform: uppercase;
	background: none;
	border: none;
	cursor: pointer;
	padding: 0 1.5rem;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.nav__btn svg {
	height: 2.4rem;
	width: 2.4rem;
	fill: #326273;
	margin-right: 0.7rem;
	-webkit-transform: translateY(-1px);
	transform: translateY(-1px);
}
.nav__btn:focus {
	outline: none;
}
.nav__btn:hover {
	background-color: #f2efee;
}

.bookmarks {
	position: absolute;
	right: -2.5rem;
	z-index: 10;
	width: 40rem;
	background-color: #fff;
	-webkit-box-shadow: 0 0.8rem 5rem 2rem rgba(97, 85, 81, 0.1);
	box-shadow: 0 0.8rem 5rem 2rem rgba(97, 85, 81, 0.1);
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all 0.5s 0.2s;
	transition: all 0.5s 0.2s;
}
.bookmarks__list {
	list-style: none;
}
.bookmarks__field {
	cursor: pointer;
	padding: 0 4rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 100%;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.bookmarks__field:hover {
	background-color: #f2efee;
}
.bookmarks:hover,
.nav__btn--bookmarks:hover + .bookmarks {
	visibility: visible;
	opacity: 1;
}

.preview__link:link,
.preview__link:visited {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 1.5rem 3.25rem;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	border-right: 1px solid #fff;
	text-decoration: none;
}
.preview__link:hover {
	background-color: #f2efee;
	-webkit-transform: translateY(-2px);
	transform: translateY(-2px);
}
.preview__link--active {
	background-color: rgba(50, 98, 115, 0.1);
}
.preview__fig {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 5.8rem;
	flex: 0 0 5.8rem;
	border: solid 2px #782d14;
	border-radius: 50%;
	overflow: hidden;
	height: 5.8rem;
	margin-right: 2rem;
	position: relative;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}
.preview__fig::before {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(114, 22, 1, 0.698);
	opacity: 0.4;
}
.preview__fig img {
	display: block;
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.preview__data {
	display: grid;
	width: 100%;
	grid-template-columns: 1fr 2rem;
	row-gap: 0.1rem;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.preview__title {
	grid-column: 1/-1;
	font-size: 1.45rem;
	color: #326273;
	text-transform: capitalize;
	font-weight: 600;
	text-overflow: ellipsis;
	max-width: 25rem;
	white-space: nowrap;
	overflow: hidden;
}
.preview__publisher {
	font-size: 1.15rem;
	color: #918581;
	text-transform: capitalize;
	font-weight: 600;
}
.preview__user-generated {
	background-color: #eeeae8;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 2rem;
	width: 2rem;
	border-radius: 10rem;
	margin-left: auto;
	margin-right: 1.75rem;
}
.preview__user-generated svg {
	height: 1.2rem;
	width: 1.2rem;
	fill: #326273;
}

.search-results {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	background-color: inherit;
}

.results {
	list-style: none;
	margin-bottom: 2rem;
}

.pagination {
	margin-top: auto;
	padding: 0 3.5rem;
}
.pagination::after {
	content: "";
	display: table;
	clear: both;
}
.pagination__btn--prev {
	float: left;
	border-radius: 0.8rem;
}
.pagination__btn--next {
	float: right;
	border-radius: 0.8rem;
}

.copyright {
	color: #918581;
	font-size: 1.2rem;
	padding: 0 3.5rem;
	margin-top: 4rem;
}
.copyright .twitter-link:link,
.copyright .twitter-link:visited {
	color: #918581;
}

.recipe {
	background-color: inherit;
	border-left: 1px solid rgba(0, 0, 0, 0.0509803922);
}
.recipe__fig {
	height: 32rem;
	position: relative;
	-webkit-transform-origin: top;
	transform-origin: top;
	border: 16px solid #f9f5f3;
	background-color: #f9f5f3;
}
.recipe__img {
	width: 100%;
	display: block;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	border-radius: 0.5rem;
	border: 1px solid #782d14;
}
.recipe__title {
	position: absolute;
	bottom: 2.5rem;
	left: 50%;
	-webkit-transform: translate(-50%, 20%);
	transform: translate(-50%, 20%);
	color: #fff;
	font-weight: 700;
	font-size: 3.25rem;
	text-transform: capitalize;
	width: 50%;
	line-height: 1.95;
	text-align: center;
}
.recipe__title span {
	-webkit-box-decoration-break: clone;
	box-decoration-break: clone;
	padding: 2rem 4rem;
	border-radius: 0.8rem;
	background: rgba(19, 80, 91, 0.7);
	-webkit-box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	border: 1px solid rgba(255, 255, 255, 0.18);
}
@media only screen and (max-width: 61.25em) {
	.recipe__title {
		width: 70%;
	}
}
.recipe__details {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 2rem 4rem;
}
.recipe__info {
	font-size: 1.65rem;
	text-transform: capitalize;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.recipe__info:not(:last-child) {
	margin-right: 4.5rem;
}
.recipe__info-icon {
	height: 2.35rem;
	width: 2.35rem;
	fill: #326273;
	margin-right: 1.15rem;
}
.recipe__info-data {
	min-width: 1.8rem;
	font-weight: 700;
}
.recipe__info-text {
	min-width: 5.6rem;
}
.recipe__info-buttons {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	gap: 2rem;
	margin-left: 1.6rem;
	-webkit-transform: translateY(-1px);
	transform: translateY(-1px);
	border: 1px solid rgba(50, 98, 115, 0.4);
	padding: 0.7rem 1.4rem;
	border-radius: 2rem;
}
.recipe__user-generated {
	background-color: #eeeae8;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 4rem;
	width: 4rem;
	border-radius: 10rem;
	margin-left: auto;
	margin-right: 1.75rem;
}
.recipe__user-generated svg {
	height: 2.25rem;
	width: 2.25rem;
	fill: #326273;
}
.recipe__ingredients {
	padding: 5rem 8rem;
	font-size: 1.6rem;
	line-height: 1.4;
	background-color: rgba(19, 80, 91, 0.09);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 0 2rem;
	border-radius: 1.4rem;
}
.recipe__ingredient-list {
	margin-top: 1rem;
	color: black;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 2.5rem 3rem;
	list-style: none;
}
.recipe__ingredient {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.recipe__icon {
	height: 2rem;
	width: 2rem;
	fill: #326273;
	margin-right: 1.1rem;
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	margin-top: 0.1rem;
}
.recipe__quantity {
	margin-right: 0.5rem;
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}
.recipe__directions {
	padding: 5rem 10rem;
	padding-bottom: 5rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.recipe__directions-text {
	font-size: 1.7rem;
	text-align: center;
	margin-bottom: 3.5rem;
	color: #918581;
}
.recipe__publisher {
	font-weight: 700;
}
.recipe__btn {
	gap: 1rem;
}

.add-recipe-window {
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 100rem;
	background-color: white;
	border-radius: 9px;
	padding: 5rem 6rem;
	-webkit-box-shadow: 0 4rem 6rem rgba(0, 0, 0, 0.25);
	box-shadow: 0 4rem 6rem rgba(0, 0, 0, 0.25);
	z-index: 1000;
}
.add-recipe-window .btn--close-modal {
	font-family: inherit;
	color: inherit;
	position: absolute;
	top: 0.5rem;
	right: 1.6rem;
	font-size: 3.5rem;
	cursor: pointer;
	border: none;
	background: none;
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.4);
	-webkit-backdrop-filter: blur(12px);
	backdrop-filter: blur(12px);
	z-index: 100;
}

.hidden {
	visibility: hidden;
	opacity: 0;
}

.upload {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 4rem 6rem;
}
.upload__column {
	display: grid;
	grid-template-columns: 1fr 2.8fr;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	gap: 1.5rem;
}
.upload__column label {
	font-size: 1.5rem;
	font-weight: 600;
	color: inherit;
}
.upload__column input {
	font-size: 1.5rem;
	padding: 0.8rem 1rem;
	border: 1px solid #ddd;
	border-radius: 0.5rem;
}
.upload__column input::-webkit-input-placeholder {
	color: #d3c7c3;
}
.upload__column input::-moz-placeholder {
	color: #d3c7c3;
}
.upload__column input:-ms-input-placeholder {
	color: #d3c7c3;
}
.upload__column input::-ms-input-placeholder {
	color: #d3c7c3;
}
.upload__column input::placeholder {
	color: #d3c7c3;
}
.upload__column input:focus {
	outline: none;
	border: 1px solid #326273;
	background-color: #f9f5f3;
}
.upload__column button {
	grid-column: 1 / span 2;
	justify-self: center;
	margin-top: 1rem;
}
.upload__heading {
	font-size: 2.25rem;
	font-weight: 700;
	text-transform: uppercase;
	margin-bottom: 1rem;
	grid-column: 1/-1;
}
.upload__btn {
	grid-column: 1/-1;
	justify-self: center;
} /*# sourceMappingURL=main.css.map */
