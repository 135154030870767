// // $color-primary: #f59a83;
// $color-primary: #f38e82;
// $color-grad-1: #fbdb89;
// $color-grad-2: #f48982;

//NEWLY DISCOVERED DARK [BLUE] THEME SHADE: 0c1f31

$color-primary: #326273;
$color-grad-1: #13505b;
$color-grad-2: #6b5500;

$color-grey-light-1: #f9f5f3; // Light background
$color-grey-light-2: #f2efee; // Light lines
$color-grey-light-3: #d3c7c3; // Light text (placeholder)
$color-grey-dark-1: #615551; // Normal text
$color-grey-dark-2: #918581; // Lighter text

$gradient: linear-gradient(to right bottom, $color-grad-1, $color-grad-2);

$bp-large: 78.15em; // 1250px
$bp-medium: 61.25em; // 980px
$bp-small: 37.5em; // 600px
$bp-smallest: 31.3em; //  500px

* {
	margin: 0;
	padding: 0;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

html {
	box-sizing: border-box;
	// font-size: 62.5%;
	font-size: 64%;

	@media only screen and (max-width: $bp-medium) {
		font-size: 50%;
	}
}

body {
	font-family: "Comic Neue", cursive;
	font-weight: 400;
	line-height: 1.6;
	color: $color-grey-dark-1;
	// background-image: $gradient;
	background-color: $color-grey-light-1;
	background-size: cover;
	background-repeat: no-repeat;
	min-height: calc(100vh - 2 * 4vw);

	//TRYING OUT THE FONT-SMOOTH FEATURE
	font-smooth: antialiased;
	-moz-osx-font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
}

.container {
	border: 0.1px solid rgba(0, 0, 0, 0.2);
	max-width: 125rem;
	min-height: 117rem;
	margin: 5rem auto;
	background-color: #fff;
	border-radius: 2px;
	overflow: hidden;
	// box-shadow: 11px 15px 2px transparentize($color-grey-dark-2, 0.5);
	box-shadow: 10px 10px #0000521a;

	display: grid;
	grid-template-rows: 10rem minmax(100rem, auto);
	grid-template-columns: 1fr 3fr;
	grid-template-areas:
		"head head"
		"list recipe";

	@media only screen and (max-width: $bp-large) {
		max-width: 100%;
		margin: 0;
		border-radius: 0;
	}
}
