%btn {
	// background-image: $gradient;
	background-color: $color-primary;
	border-radius: 0rem 1rem 1rem 0rem;
	border: none;
	text-transform: uppercase;
	color: #fff;
	cursor: pointer;
	display: flex;
	align-items: center;
	transition: all 0.2s;
	//

	&:hover {
		transform: scale(1.05);
	}

	&:focus {
		outline: none;
	}

	& > *:first-child {
		margin: 0.3rem;
	}
}

.btn {
	@extend %btn;

	padding: 1rem 2rem;
	font-size: 1.5rem;
	font-weight: 600;

	svg {
		height: 2.25rem;
		width: 2.25rem;
		fill: currentColor;
	}
}

.btn--small {
	&,
	&:link,
	&:visited {
		@extend %btn;

		font-size: 1.4rem;
		font-weight: 600;
		padding: 1.25rem 2.25rem;
		text-decoration: none;

		svg {
			height: 1.75rem;
			width: 1.75rem;
			fill: currentColor;
		}
	}
}

.btn--inline {
	font-family: "Comic Neue", cursive;
	color: $color-primary;
	font-size: 1.3rem;
	font-weight: 600;
	border: none;
	background-color: $color-grey-light-1;
	padding: 0.8rem 1.2rem;
	border-radius: 10rem;
	cursor: pointer;

	display: flex;
	align-items: center;
	transition: all 0.2s;

	svg {
		height: 1.6rem;
		width: 1.6rem;
		fill: currentColor;
		margin: 0 0.2rem;
	}

	span {
		margin: 0 0.4rem;
	}

	&:hover {
		color: $color-grad-2;
		background-color: $color-grey-light-2;
	}

	&:focus {
		outline: none;
	}
}

.btn--round {
	// background-image: $gradient;
	background-color: #326273;
	border-radius: 50%;
	border: none;
	cursor: pointer;
	height: 4.5rem;
	width: 4.5rem;
	// margin-left: auto;
	transition: all 0.2s;

	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		transform: scale(1.07);
	}

	&:focus {
		outline: none;
	}

	svg {
		height: 2.5rem;
		width: 2.5rem;
		fill: #fff;
	}
}

.btn--tiny {
	height: 2rem;
	width: 2rem;
	border: none;
	background: none;
	cursor: pointer;

	svg {
		height: 100%;
		width: 100%;
		fill: $color-primary;
		transition: all 0.3s;
	}

	&:focus {
		outline: none;
	}

	&:hover svg {
		fill: $color-grad-2;
		transform: translateY(-1px);
	}

	&:active svg {
		fill: $color-grad-2;
		transform: translateY(0);
	}

	&:not(:last-child) {
		margin-right: 0.3rem;
	}
}

.heading--2 {
	font-size: 2rem;
	font-weight: 700;
	color: $color-primary;
	text-transform: uppercase;
	margin-bottom: 2.5rem;
	text-align: center;
	// transform: skewY(-3deg);
}

.link:link,
.link:visited {
	color: $color-grey-dark-2;
}

@keyframes spinner {
	0%,
	100% {
		animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
	}
	0% {
		transform: rotateY(0deg);
	}
	50% {
		transform: rotateY(1800deg);
		animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
	}
	100% {
		transform: rotateY(3600deg);
	}
}

.spinner {
	display: block;
	transform: translateZ(1px);
	> div {
		display: block;
		height: 5rem;
		width: 5rem;
		margin: 15rem auto;
		border-radius: 50%;
		background: transparentize($color-primary, 0.5);
		animation: spinner 5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0);
	}

	100% {
		transform: rotate(360deg);
	}
}

.message,
.error {
	max-width: 40rem;
	margin: 0 auto;
	padding: 5rem 4rem;

	display: flex;
	align-items: end;

	svg {
		color: #326273;
		height: 4rem;
		width: 4rem;
		fill: $color-primary;
		transform: translateY(-0.3rem);
	}

	p {
		margin-left: 1.5rem;
		font-size: 1.8rem;
		line-height: 1.5;
		font-weight: 600;
	}
}
