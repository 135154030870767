.recipe {
	background-color: inherit;
	border-left: 1px solid #0000000d;

	///////////
	// FIGURE
	&__fig {
		height: 32rem;
		position: relative;
		// transform: scale(1.04) translateY(-1px);
		transform-origin: top;
		border: 16px solid $color-grey-light-1;
		background-color: $color-grey-light-1;

		// &::before {
		//   border-radius: 0.6rem;
		//   content: '';
		//   display: block;
		//   height: 100%;
		//   width: 100%;
		//   position: absolute;
		//   top: 0;
		//   left: 0;
		//   background-image: linear-gradient(
		//     to right bottom,
		//     $color-grad-1,
		//     rgba(0, 0, 0, 0.414)
		//   );
		//   opacity: 0.7;
		// }
	}

	&__img {
		border-radius: 0.6rem;
		width: 100%;
		display: block;
		height: 100%;
		object-fit: cover;
		border-radius: 1rem;
	}

	&__title {
		position: absolute;
		bottom: 2.5rem;
		left: 50%;
		transform: translate(-50%, 20%);
		color: #fff;
		font-weight: 700;
		font-size: 3.25rem;
		text-transform: capitalize;
		width: 50%;
		line-height: 1.95;
		text-align: center;

		span {
			box-decoration-break: clone;
			padding: 2rem 4rem;
			border-radius: 0.8rem;
			// background-image: linear-gradient(
			//   to right bottom,
			//   $color-grad-1,
			//   $color-grad-2
			// );
			// background-color: $color-grad-1;

			background: rgba($color-grad-1, 0.7);
			box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);

			border: 1px solid rgba(255, 255, 255, 0.18);
		}

		@media only screen and (max-width: $bp-medium) {
			width: 70%;
		}
	}

	///////////
	// DETAILS
	&__details {
		display: flex;
		align-items: center;
		padding: 2rem 4rem;
	}

	&__info {
		font-size: 1.65rem;
		text-transform: capitalize;
		display: flex;
		align-items: center;

		&:not(:last-child) {
			margin-right: 4.5rem;
		}
	}

	&__info-icon {
		height: 2.35rem;
		width: 2.35rem;
		fill: $color-primary;
		margin-right: 1.15rem;
	}

	&__info-data {
		min-width: 1.8rem;
		font-weight: 700;
	}

	&__info-text {
		min-width: 5.6rem;
	}

	&__info-buttons {
		display: flex;
		gap: 2rem;
		margin-left: 1.6rem;
		transform: translateY(-1px);
		border: 1px solid rgba($color-primary, 0.4);
		padding: 0.7rem 1.4rem;
		border-radius: 2rem;
	}

	&__user-generated {
		background-color: darken($color-grey-light-2, 2%);

		display: flex;
		align-items: center;
		justify-content: center;
		height: 4rem;
		width: 4rem;
		border-radius: 10rem;

		margin-left: auto;
		margin-right: 1.75rem;

		svg {
			height: 2.25rem;
			width: 2.25rem;
			fill: $color-primary;
		}
	}

	///////////
	// INGREDIENTS
	&__ingredients {
		padding: 5rem 8rem;
		font-size: 1.6rem;
		line-height: 1.4;
		background-color: rgba($color-grad-1, 0.09);
		display: flex;
		flex-direction: column;
		align-items: center;

		margin: 0 2rem;
		border-radius: 1.4rem;
	}

	&__ingredient-list {
		margin-top: 1rem;
		color: black;
		// font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 2.5rem 3rem;
		list-style: none;
	}

	&__ingredient {
		display: flex;
	}

	&__icon {
		height: 2rem;
		width: 2rem;
		fill: $color-primary;
		margin-right: 1.1rem;
		flex: 0 0 auto;
		margin-top: 0.1rem;
	}

	&__quantity {
		margin-right: 0.5rem;
		flex: 0 0 auto;
	}

	///////////
	// DIRECTIONS
	&__directions {
		padding: 5rem 10rem;
		padding-bottom: 5rem;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__directions-text {
		font-size: 1.7rem;
		text-align: center;
		margin-bottom: 3.5rem;
		color: $color-grey-dark-2;
	}

	&__publisher {
		font-weight: 700;
	}

	&__btn {
		gap: 1rem;
	}
}
